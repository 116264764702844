import React from "react";

import { PollResponse } from "api/poll";
import dayjs from "dayjs";
import { PageProps } from "gatsby";
import usePollUpdater from "hooks/polls/usePollUpdater";
import PollDetail from "templates/poll/detail";

import "dayjs/plugin/utc";

interface IProps extends PageProps<any, PollResponse, any> {
  params: {
    id: string;
  };
}

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const PollDetailRedirect: React.FunctionComponent<IProps> = ({
  params,
  ...props
}) => {
  const { id } = params;
  const { poll } = usePollUpdater({
    id,
  });

  console.log(`[poll/detail/[id]]: ${id}`);
  if (!poll) return null;

  return <PollDetail pageContext={poll!} />;
};

export default PollDetailRedirect;
